import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/_components/assets/bibi-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/app/_components/assets/nudafa-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/app/_components/assets/trto-logo.png");
;
import(/* webpackMode: "eager" */ "/app/src/app/(home)/_components/assets/background-call-to-action.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/app/(home)/_components/assets/HomePageLive/bibi.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/app/(home)/_components/assets/HomePageLive/trto.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/app/(home)/_components/assets/HomePageLive/zes.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["HomePagePrimaryFeatures"] */ "/app/src/app/(home)/_components/HomePagePrimaryFeatures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomePageSecondaryFeaturesJustText"] */ "/app/src/app/(home)/_components/HomePageSecondaryFeaturesJustText.tsx");
